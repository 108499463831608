/* Accordion Component TS */

export function initAccordion() {
  const accordionToggles = document.querySelectorAll(
    '[data-bs-toggle="collapse"]'
  );

  if (accordionToggles.length > 0) {
    accordionToggles.forEach((toggle) => {
      toggle.addEventListener('click', function (event) {
        const selector = this.getAttribute('data-bs-target');
        const collapseElement = document.querySelector(selector);

        if (
          !collapseElement ||
          collapseElement.classList.contains('collapsing')
        ) {
          // Skip if the target element doesn't exist or is already in the process of collapsing/expanding
          return;
        }

        const isCollapsed = !collapseElement.classList.contains('show');
        this.setAttribute('aria-expanded', String(isCollapsed));

        if (isCollapsed) {
          this.classList.remove('collapsed'); // Remove '.collapsed' from the button
          // If the section is about to be expanded
          collapseElement.style.height = '0px';
          collapseElement.classList.remove('collapse');
          collapseElement.classList.add('collapsing');
          setTimeout(() => {
            collapseElement.style.height = `${collapseElement.scrollHeight}px`;
          }, 0);

          setTimeout(() => {
            collapseElement.classList.remove('collapsing');
            collapseElement.classList.add('collapse', 'show');
            collapseElement.style.height = '';
          }, 350);
        } else {
          this.classList.add('collapsed'); // Add '.collapsed' to the button

          // If the section is about to be collapsed
          collapseElement.style.height = `${collapseElement.scrollHeight}px`;
          setTimeout(() => {
            collapseElement.style.height = '0px';
          }, 0);
          collapseElement.classList.remove('collapse', 'show');
          collapseElement.classList.add('collapsing');

          setTimeout(() => {
            collapseElement.classList.remove('collapsing');
            collapseElement.classList.add('collapse');
            collapseElement.style.height = '';
          }, 350);
        }
      });
    });
  }

  /* Expand/Collapse Button Trigger */
  const expandCollapseButtons = document.querySelectorAll(
    '.expand-collapse-btn'
  );

  if (accordionToggles.length > 0 && expandCollapseButtons) {
    expandCollapseButtons.forEach((button) => {
      button.addEventListener('click', function () {
        const parentSelector = this.getAttribute('data-parent');
        const selector = parentSelector ? `${parentSelector} .accordion-collapse` : '.accordion-collapse';
        const accordions = document.querySelectorAll(selector);
  
        // Toggle state tracking on button click
        const shouldExpand = this.textContent.includes('Expand');
  
        accordions.forEach((collapseElement) => {
          const toggle = document.querySelector(`[data-bs-target="#${collapseElement.id}"]`);
          if (shouldExpand) {
            // Expand the accordion items
            toggle.classList.remove('collapsed');
            collapseElement.classList.add('show');
            (collapseElement as HTMLElement).style.height = '';// Adjust height if using static height values
            toggle.setAttribute('aria-expanded', 'true');
          } else {
            // Collapse the accordion items
            toggle.classList.add('collapsed');
            collapseElement.classList.remove('show');
            (collapseElement as HTMLElement).style.height = '0px'; // Adjust height if using static height values
            toggle.setAttribute('aria-expanded', 'false');
          }
        });
  
        // Update button text based on the toggle state
        this.textContent = shouldExpand ? 'Collapse All' : 'Expand All';
      });
    });  
  }
}