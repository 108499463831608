export default function initStickySidebar() {
  const stickySidebar: HTMLElement = document.querySelector(
    '.content-sidebar.sticky'
  );
  if (!stickySidebar) return;

  let lastScrollPosition = 0;

  window.addEventListener('scroll', function (e) {
    const currentScrollPosition = window.scrollY;
    let scrollDirection;
    if (currentScrollPosition > lastScrollPosition) {
      scrollDirection = 'down';
    } else if (currentScrollPosition < lastScrollPosition) {
      scrollDirection = 'up';
    }

    const sidebarBottom = stickySidebar.getBoundingClientRect().bottom;
    const viewportHeight = window.innerHeight;
    const topDistance = 160;
    const sidebarTop = stickySidebar.getBoundingClientRect().top;

    if (scrollDirection === 'down') {
      if (sidebarBottom > viewportHeight - 30) {
        const scrollAmount = currentScrollPosition - lastScrollPosition;
        stickySidebar.style.top = `${sidebarTop - scrollAmount}px`;
      }
    } else if (scrollDirection === 'up') {
      if (sidebarTop < topDistance) {
        const scrollAmount = currentScrollPosition - lastScrollPosition;
        stickySidebar.style.top = `${sidebarTop - scrollAmount}px`;
      }
    }

    lastScrollPosition = currentScrollPosition;
  });
}
