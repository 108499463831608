export function initDropdownFilter() {
  // Function to add event listeners to dropdown links
  const resetButton = document.getElementById('dropdown-reset-btn');

  // Function to show the reset button
  function showResetButton() {
    if (resetButton) {
      resetButton.classList.remove('hidden');
    }
  }

  // Function to hide the reset button
  function hideResetButton() {
    if (resetButton) {
      resetButton.classList.add('hidden');
    }
  }

  // Initially hide the reset button
  hideResetButton();

  // Scope selection of dropdown links to those within #dropdownFilter
  document
    .querySelectorAll('#dropdownFilter .menu-dropdown-link')
    .forEach(function (link) {
      link.addEventListener('click', function (e) {
        e.preventDefault();

        const itemText = this.textContent.trim();
        if (
          !document.querySelector(
            `#dropdown-selected-items [data-id="${itemText}"]`
          )
        ) {
          const button = document.createElement('span');
          button.setAttribute('data-id', itemText);
          button.className = 'btn-dropdown-selected';
          button.innerHTML = `${itemText}<button type="button" tabindex="-1" class="icon-close"></button>`;
          document.getElementById('dropdown-selected-items').innerHTML = '';
          document
            .getElementById('dropdown-selected-items')
            .appendChild(button);

          // Add event listener for removing button
          button
            .querySelector('.icon-close')
            .addEventListener('click', function () {
              this.parentNode.remove(); // Remove the selected button
              filterContent(); // Apply the filter based on remaining selected buttons

              // After removal, check if any .btn-dropdown-selected buttons are left
              if (
                document.querySelectorAll(
                  '#dropdown-selected-items .btn-dropdown-selected'
                ).length === 0
              ) {
                hideResetButton(); // Call hideResetButton() if no buttons are left
              }
            });

          filterContent();
        }

        // Show the reset button when a dropdown item is selected
        showResetButton();
      });
    });

  const caasppItems = [
    'Smarter Balanced for ELA and Mathematics',
    'California Science Test (CAST)',
    'California Spanish Assessment (CSA)',
    'California Alternate Assessments (CAAs)',
  ];
  const elpacItems = [
    'Initial ELPAC',
    'Initial Alternate ELPAC',
    'Summative ELPAC',
    'Summative Alternate ELPAC',
  ];
  // Function to filter content blocks
  function filterContent() {
    const selectedIds = [
      ...document.querySelectorAll('#dropdown-selected-items [data-id]'),
    ].map((el) => el.getAttribute('data-id'));

    document.querySelectorAll('.content-card-filter').forEach(function (card) {
      let hasVisibleItems = false;

      card.querySelectorAll('.content-card-item').forEach(function (item) {
        const headingElement = item.querySelector('.content-card-item-heading');
        // Check if the .content-card-item-heading element exists
        if (headingElement) {
          const itemHeading = headingElement.textContent.trim();
          if (selectedIds.length === 0 || selectedIds.includes(itemHeading)) {
            (item as HTMLElement).style.display = '';
            hasVisibleItems = true;
          } else {
            (item as HTMLElement).style.display = 'none';
          }
        } else {
          //console.warn('Warning: A .content-card-item is missing a .content-card-item-heading element.');
        }
      });

      // Optionally hide the entire card if it has no visible items
      (card as HTMLElement).style.display = hasVisibleItems ? '' : 'none';
    });

    document
      .querySelectorAll(
        '.content-frame:has(.content-card-block:not(.content-card-filter))'
      )
      .forEach(function (frame) {
        if (selectedIds.length === 0) {
          frame
            .querySelectorAll('.content-card-block:not(.content-card-filter)')
            .forEach((card) => {
              (card as HTMLElement).style.display = '';
            });
          return;
        }

        let hasVisibleCards = false;

        frame
          .querySelectorAll('.content-card-block:not(.content-card-filter)')
          .forEach((card) => {
            const cardSystem = card
              .querySelector('.content-card-logo-block')
              .textContent.trim()
              .toLowerCase();

            const isNotBothSystems =
              cardSystem === 'caaspp' || cardSystem === 'elpac';

            const selectedItems = selectedIds.filter((id) =>
              cardSystem === 'caaspp'
                ? caasppItems.includes(id)
                : elpacItems.includes(id)
            );

            if (selectedItems.length > 0 || !isNotBothSystems) {
              (card as HTMLElement).style.display = '';
              hasVisibleCards = true;
            } else {
              (card as HTMLElement).style.display = 'none';
            }
          });

        // (frame as HTMLElement).style.display = hasVisibleCards ? '' : 'none';
      });
  }

  // Reset button functionality
  if (resetButton) {
    resetButton.addEventListener('click', function (e) {
      e.preventDefault();
      document
        .querySelectorAll('#dropdown-selected-items .btn-dropdown-selected')
        .forEach((button) => button.remove());
      filterContent(); // Reset the filter to show all content blocks

      // Hide the reset button after resetting
      hideResetButton();
    });
  }
}
