import Modal from './modal';

declare global {
  interface Window {
    alertsData: any;
  }
}

export default async function systemAlerts() {
  if (!window.alertsData) return;
  const dataUrl = window.alertsData;

  const fetchAlerts = async () => {
    const response = await fetch(dataUrl);
    return response.json();
  };

  let alertsData = {
    status: 'ok',
    known_issues_url: '',
    downtime_url: '',
    downtime: [],
    issue: [],
    error: [],
    resolved: [],
    alert: [],
  };
  try {
    alertsData = await fetchAlerts();
  } catch (error) {
    console.error('Error fetching alerts data:', error);
  }

  const storedAlertsData = localStorage.getItem('alertsData');

  let displayAlerts = false;

  if (storedAlertsData !== JSON.stringify(alertsData)) {
    displayAlerts = true;
  }

  localStorage.setItem('alertsData', JSON.stringify(alertsData));

  const items = {
    downtime: alertsData.downtime || [],
    issue: alertsData.issue || [],
    error: alertsData.error || [],
    resolved: alertsData.resolved || [],
    alert: alertsData.alert || [],
  };

  const headerStatusField = document.querySelectorAll(
    'header [title="System Status"]'
  )!;
  const headerIssuesCount = document.querySelectorAll(
    'header [issue-counter]'
  )!;
  const headerDowntimeCount = document.querySelectorAll(
    'header [downtime-counter]'
  )!;

  type Status = 'ok' | 'issue' | 'downtime' | 'error';

  let status: Status = 'ok';
  if (
    alertsData.status === 'ok' ||
    alertsData.status === 'issue' ||
    alertsData.status === 'downtime' ||
    alertsData.status === 'error'
  ) {
    status = alertsData.status;
  }

  const statusMarkup = {
    ok: `<span class="menu-status menu-status-success">
    <span class="menu-status-icon icon-check"></span>
    OK
  </span>`,
    issue: `<span class="menu-status menu-status-issue">
  <span class="menu-status-icon icon-info"></span>
  Issue
</span>`,
    downtime: `<span class="menu-status menu-status-alert">
<span class="menu-status-icon icon-info"></span>
System Downtime
</span>`,
    error: `<span class="menu-status menu-status-error">
<span class="menu-status-icon icon-error1"></span>
System Error
</span>`,
  };
  const statusIconColors = {
    ok: 'menu-counter-success',
    issue: 'menu-counter-issue',
    downtime: 'menu-counter-alert',
    error: 'menu-counter-error',
  };

  headerStatusField.forEach(
    (i) =>
      (i.innerHTML = `<span>System Status</span>${statusMarkup[status]}<i class="icon-chevron-down"></i>`)
  );

  const totalCount = items.issue.length + items.error.length;
  headerIssuesCount.forEach((i) => {
    i.classList.remove('menu-counter-success');
    i.classList.add(statusIconColors[status]);
    i.innerHTML = totalCount.toString();
    if (items.issue.length === 0) {
      (i as HTMLElement).style.display = 'none';
    } else {
      (i as HTMLElement).style.display = 'flex';
    }
  });

  headerDowntimeCount.forEach((i) => {
    i.innerHTML = items.downtime.length.toString();
    i.classList.add(statusIconColors['downtime']);
    if (items.downtime.length === 0) {
      (i as HTMLElement).style.display = 'none';
    } else {
      (i as HTMLElement).style.display = 'flex';
    }
  });

  const issuesModalMarkup = `
  <div class="modal modal-issueDetected fade" id="issueDetectedModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="issueDetectedModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <i class="icon-alert-filled"></i>
          <h2 class="modal-title" id="issueDetectedModalLabel">Issue Detected</h2>
          <p>${items.issue[0]?.date}</p>
        </div>
        <!-- Modal Header -->

        <!-- Modal Body -->
        <div class="modal-body
        ">
          ${items.issue
            .map((item) => {
              return `<p>${item.description}</p>`;
            })
            .join('')}
          <div class="alert !block">
            <p>For additional details, refer to the <a href="${
              alertsData.known_issues_url
            }">Known Issues</a> web page under the <strong>System Status</strong> tab.</p>
          </div>
        </div>
        <!-- Modal Header -->

        <!-- Modal Footer/Dismiss -->
        <div class="modal-footer">
          <button type="button" class="btn-primary-lg" data-bs-dismiss="modal">Dismiss</button>
        </div>
        <!-- Modal Footer/Dismiss -->
      </div>
    </div>
  </div>
  `;

  const errorsModalMarkup = `
  <div class="modal modal-systemError fade" id="systemErrorModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="systemErrorModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <i class="icon-alert-2"></i>
        <h2 class="modal-title" id="systemErrorModalLabel">System Error</h2>
        <p>${items.error[0]?.date}</p>
      </div>
      <!-- Modal Header -->

      <!-- Modal Body -->
      <div class="modal-body">
      ${items.error
        .map((item) => {
          return `<p>${item.description}</p>`;
        })
        .join('')}
        <div class="alert !block">
          <p>For additional details, refer to the <a href="${
            alertsData.known_issues_url
          }">Known Issues</a> web page under the <strong>System Status</strong> tab.</p>
        </div>
      </div>
      <!-- Modal Body -->

      <!-- Modal Footer/Dismiss -->
      <div class="modal-footer">
        <button type="button" class="btn-primary-lg" data-bs-dismiss="modal">Dismiss</button>
      </div>
      <!-- Modal Footer/Dismiss -->
    </div>
  </div>
</div>
  `;

  const downtimeModalMarkup = `
  <div class="modal modal-systemDowntime fade" id="systemDowntimeModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="systemDowntimeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <i class="icon-clock"></i>
        <h2 class="modal-title" id="systemDowntimeModalLabel">System Downtime</h2>
        <p>${items.downtime[0]?.date}</p>
      </div>
      <!-- Modal Header -->

      <!-- Modal Body -->
      <div class="modal-body">
      ${items.downtime
        .map((item) => {
          return `<p>${item.description}</p>`;
        })
        .join('')}
        <div class="alert">
          <p>For additional details, refer to the <a href="${
            alertsData.downtime_url
          }">System Downtime</a> web page under the <strong>System Status</strong> tab.</p>
        </div>
      </div>
      <!-- Modal Body -->

      <!-- Modal Footer/Dismiss -->
      <div class="modal-footer">
        <button type="button" class="btn-primary-lg" data-bs-dismiss="modal">Dismiss</button>
      </div>
      <!-- Modal Footer/Dismiss -->
    </div>
  </div>
</div>
  `;

  const alertModalMarkup = `
  <div class="modal modal-genericAlert fade" id="genericAlertModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="genericAlertModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header">
        <i class="icon-alert-2"></i>
        <h2 class="modal-title" id="genericAlertModalLabel">${
          items.alert[0]?.title || 'Alert'
        }</h2>
        <p>${items.alert[0]?.date}</p>
      </div>
      <!-- Modal Header -->

      <!-- Modal Body -->
      <div class="modal-body">
      ${items.alert
        .map((item) => {
          return `<p>${item.description}</p>`;
        })
        .join('')}
        
      </div>
      <!-- Modal Body -->

      <!-- Modal Footer/Dismiss -->
      <div class="modal-footer">
        <button type="button" class="btn-primary-lg" data-bs-dismiss="modal">Dismiss</button>
      </div>
      <!-- Modal Footer/Dismiss -->
    </div>
  </div>
</div>
  `;

  document.body.insertAdjacentHTML('beforeend', issuesModalMarkup);
  document.body.insertAdjacentHTML('beforeend', errorsModalMarkup);
  document.body.insertAdjacentHTML('beforeend', downtimeModalMarkup);
  document.body.insertAdjacentHTML('beforeend', alertModalMarkup);

  const hasIssues = items.issue.length > 0;
  const hasErrors = items.error.length > 0;
  const hasDowntime = items.downtime.length > 0;
  const hasAlerts = items.alert.length > 0;
  let alertsViewed = false;
  if (displayAlerts) {
    if (hasIssues) {
      displayIssues();
      return;
    }
    if (hasErrors) {
      displayErrors();
      return;
    }
    if (hasDowntime) {
      displayDowntime();
      return;
    }
    if (hasAlerts) {
      displayGenericAlerts();
      return;
    }
    alertsViewed = true;
  }

  function displayIssues() {
    const issuesModal = new Modal(
      document.getElementById('issueDetectedModal')
    );
    issuesModal.show();

    document
      .getElementById('issueDetectedModal')
      .addEventListener('hidden.bs.modal', function () {
        if (alertsViewed || !displayAlerts) return;
        if (hasErrors) {
          displayErrors();
          return;
        }
        if (hasDowntime) {
          displayDowntime();
          return;
        }
        if (hasAlerts) {
          displayGenericAlerts();
          return;
        }

        alertsViewed = true;
      });
  }
  function displayErrors() {
    const errorsModal = new Modal(document.getElementById('systemErrorModal'));
    errorsModal.show();

    document
      .getElementById('systemErrorModal')
      .addEventListener('hidden.bs.modal', function () {
        if (alertsViewed || !displayAlerts) return;
        if (hasDowntime) {
          displayDowntime();
          return;
        }
        if (hasAlerts) {
          displayGenericAlerts();
          return;
        }
        alertsViewed = true;
      });
  }
  function displayDowntime() {
    const downtimeModal = new Modal(
      document.getElementById('systemDowntimeModal')
    );
    downtimeModal.show();

    document
      .getElementById('systemDowntimeModal')
      .addEventListener('hidden.bs.modal', function () {
        if (alertsViewed || !displayAlerts) return;
        if (hasAlerts) {
          displayGenericAlerts();
          return;
        }
        alertsViewed = true;
      });
  }

  function displayGenericAlerts() {
    const alertModal = new Modal(document.getElementById('genericAlertModal'));
    alertModal.show();

    document
      .getElementById('genericAlertModal')
      .addEventListener('hidden.bs.modal', function () {
        if (alertsViewed || !displayAlerts) return;
        alertsViewed = true;
      });
  }
}
