import './css/main.scss';
import '@splidejs/splide/dist/css/splide.min.css'; // Import Splide CSS

import { initDropdownMenu } from './js/dropdown-menu';
import { initAccordion } from './js/accordion';
import { initSmoothScroll } from './js/smooth-scroll';
import { initToggleArticle } from './js/toggle-article';
import { initSearchFilter } from './js/search-filter';
import { toggleBlock } from './js/search-filter';
import { initDropdownFilter } from './js/dropdown-filter';
import { initCalendarView } from './js/calendar-view';

import Splide from '@splidejs/splide';
import Tab from './js/tab';
import Modal from './js/modal';
import { jumpToMobile } from './js/jump-to';
import systemAlerts from './js/system-alerts';
import initDowntimeListView from './js/downtime-list';
import initStickySidebar from './js/sticky-sidebar';
import initTrainings from './js/training-page';

export default {
  Modal,
  Tab,
};

/* Function Init */
document.addEventListener('DOMContentLoaded', () => {
  jumpToMobile();
  initTrainings();
  initDropdownMenu();
  initAccordion();
  initSmoothScroll();
  initToggleArticle();
  initSearchFilter();
  initDropdownFilter();
  initDowntimeListView();
  initCalendarView();
  systemAlerts();
  initStickySidebar();

  /* Check if the Splide element exists before initializing */
  const splideEl = document.querySelector('.splide');
  if (splideEl) {
    const numberOfSlides = splideEl.querySelectorAll('.splide__slide').length;
    const heroSplide = new Splide('.splide', {
      arrows: false,
      type: 'fade',
      perPage: 1,
      autoplay: false,
      rewind: false,
      pagination: false,
      drag: false,
      keyboard: false,
      slideFocus: false,
      live: false,
      speed: 350,
      pauseOnFocus: true,
      pauseOnHover: true,
      role: 'presentation',
      label: '',
      labelledby: '',
      start: Math.floor(Math.random() * numberOfSlides),
    });

    heroSplide.mount();
  }

  /* Event for toggle clicks with a single listener */
  document.addEventListener('click', function (e) {
    let target: HTMLElement | null = e.target as HTMLElement;

    // Traverse up the DOM tree to find an element matching '[data-toggle="block"]'
    while (target && !target.matches('[data-toggle="block"]')) {
      if (target.parentElement) {
        target = target.parentElement;
      } else {
        target = null; // Explicitly setting to null when no parent matches
      }
    }

    // If a matching element is found, proceed with the toggle
    if (target) {
      e.preventDefault();
      const targetSelector = target.getAttribute('data-target');
      if (targetSelector) {
        toggleBlock(target, targetSelector);
      }
    }
  });
});
