export function jumpToMobile() {
  const contentSidebar = document.querySelector('aside.content-sidebar');
  const contentSidebarMenu = document.querySelector(
    'aside.content-sidebar .content-sidebar-menu'
  );
  const mainContentDiv = document.querySelector('#main-contents');

  if (contentSidebar && contentSidebarMenu && mainContentDiv) {
    const pageSidebarContentArray = Array.from(contentSidebarMenu.children).map(
      (item) => {
        let itemWithoutIcon = item
          .querySelector('a')
          .innerHTML.replace(/<i.*<\/i>/, '');
        return {
          title: itemWithoutIcon.trim(),
          href: item.querySelector('a').getAttribute('href'),
        };
      }
    );

    let dropdownHtmlStart = `
    <div class="dropdown-group" id="jumpToDropdown">
  <div class="dropdown relative w-fit">
    <button class="btn-dropdown dropdown-toggle" type="button" id="jumpToDropdownToggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>Jump to a section on this page</span>
      <i class="icon-chevron-down"></i>
    </button>
    <ul class="dropdown-menu" aria-labelledby="jumpToDropdownToggle">`;

    let dropdownHtmlEnd = `
    </ul>
  </div>
</div>`;

    let dropdownHtml = dropdownHtmlStart;
    pageSidebarContentArray.forEach((item) => {
      dropdownHtml += `
      <li>
        <a
          href="${item.href}"
          class="menu-dropdown-link"
          title="${item.title}"
        >
          <span>${item.title}</span>
        </a>
      </li>`;
    });
    dropdownHtml += dropdownHtmlEnd;

    const jumpToMenu = document.createElement('div');
    jumpToMenu.classList.add('jump-to-menu');
    jumpToMenu.innerHTML = dropdownHtml;
    mainContentDiv.prepend(jumpToMenu);
  }
}
