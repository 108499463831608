import { initAccordion } from './accordion';

declare global {
  interface Window {
    trainingDataUrl: any;
  }
}

export default function initTrainings() {
  if (!window.trainingDataUrl) return;
  const dataUrl = window.trainingDataUrl;
  fetch(dataUrl)
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to fetch training data');
      }
      return response.text();
    })
    .then((htmlData) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlData, 'text/html');

      const chronMonthItems = doc.querySelectorAll(
        '#chronological .filter-month'
      );

      const archiveItems = doc.querySelectorAll('#archive .accordion');

      const archiveItemsArray = Array.from(archiveItems).map((item) => {
        const title = item.querySelector('h3').textContent;
        const id = item.querySelector('h3').getAttribute('name');
        const content = item.querySelector('.content').innerHTML;
        const programs = Array.from(item.classList).filter(
          (className) => className === 'caaspp' || className === 'elpac'
        );
        const audiences = Array.from(item.classList).filter(
          (className) =>
            className === 'edu' ||
            className === 'admin' ||
            className === 'coord'
        );
        return {
          id,
          title,
          content,
          programs: programs,
          audiences: audiences,
        };
      });

      const onDemandItems = doc.querySelectorAll('#on-demand .accordion');

      const onDemandItemsArray = Array.from(onDemandItems).map((item) => {
        const title = item.querySelector('h3').textContent;
        const id = item.querySelector('h3').getAttribute('name');
        const content = item.querySelector('.content').innerHTML;
        const programs = Array.from(item.classList).filter(
          (className) => className === 'caaspp' || className === 'elpac'
        );
        const audiences = Array.from(item.classList).filter(
          (className) =>
            className === 'edu' ||
            className === 'admin' ||
            className === 'coord'
        );
        return {
          id,
          title,
          content,
          programs: programs,
          audiences: audiences,
        };
      });

      // const filterMonths = doc.querySelectorAll('.filter-month');
      const filterMonthsArray = Array.from(chronMonthItems).map((month) => {
        const monthName = month.querySelector('h2').textContent;
        const items = Array.from(month.querySelectorAll('.accordion')).map(
          (item) => {
            const title = item.querySelector('h3').textContent;
            const id = item.querySelector('h3').getAttribute('name');
            const content = item.querySelector('.content').innerHTML;
            const programs = Array.from(item.classList).filter(
              (className) => className === 'caaspp' || className === 'elpac'
            );
            const audiences = Array.from(item.classList).filter(
              (className) =>
                className === 'edu' ||
                className === 'admin' ||
                className === 'coord'
            );
            return {
              id,
              title,
              content,
              programs: programs,
              audiences: audiences,
            };
          }
        );
        return { month: monthName, items };
      });

      filterMonthsArray.forEach((month) => {
        const monthDiv = document.createElement('div');
        monthDiv.classList.add('filter-trainings');
        monthDiv.innerHTML = `<h3 class="filter-trainings-month">${month.month}</h3>`;
        const accordion = document.createElement('div');
        accordion.classList.add('accordion');
        month.items.forEach((item) => {
          const accordionItem = document.createElement('div');
          const programsString = item.programs.flat().join(',');
          const audiencesString = item.audiences.flat().join(',');
          const categoryString = programsString + ',' + audiencesString;
          accordionItem.dataset.category = categoryString;
          accordionItem.classList.add(
            'accordion-item',
            'accordion-item-secondary'
          );
          accordionItem.innerHTML = `
            <button
							id="UPCOMING-${item.id}"
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-${item.id}"
              aria-expanded="false"
              aria-controls="accordion-${item.id}"
            >
              ${item.title}
              <span class="accordion-icon"></span>
            </button>
            <div
              id="accordion-${item.id}"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion${month.month}"
            >
              <div class="accordion-body">
                ${item.content}
              </div>
            </div>
          `;
          accordion.appendChild(accordionItem);
        });
        monthDiv.appendChild(accordion);

        document.getElementById('upcomingTrainings').appendChild(monthDiv);
      });

      // combine the filterMonthsArray and the archiveItemsArray

      const combinedItems = filterMonthsArray
        .map((month) => {
          return month.items;
        })
        .flat();

      const combinedItemsArray = archiveItemsArray.concat(combinedItems);

      const groupByTitles = combinedItemsArray.reduce((acc, item) => {
        const title = item.title.trim();
        if (!acc[title]) {
          acc[title] = [];
        }
        acc[title].push(item);
        return acc;
      }, {});

      // get the items that are in #accordionOnDemand and add them to the groupByTitles
      // const onDemandItems = Array.from(
      //   document.querySelectorAll('#accordionOnDemand .accordion-item')
      // ).map((item) => {
      //   const title = item.querySelector('button').textContent;
      //   const content = item.querySelector('.accordion-body').innerHTML;
      //   return { title, content };
      // });

      onDemandItemsArray.forEach((item) => {
        if (!groupByTitles[item.title]) {
          groupByTitles[item.title] = [];
        }
        groupByTitles[item.title].push(item);
      });

      const sortedGroupByTitles = Object.fromEntries(
        Object.entries(groupByTitles).sort((a, b) =>
          a[0].trim().localeCompare(b[0].trim())
        )
      );

      const onDemandDiv = document.createElement('div');
      onDemandDiv.classList.add('filter-trainings');
      onDemandDiv.id = 'on-demand';
      onDemandDiv.innerHTML = `<div class="content-separator"></div><h3 class="filter-trainings-heading">On-Demand Trainings and Modules</h3>`;
      const accordion = document.createElement('div');
      accordion.classList.add('accordion');
      onDemandItemsArray.forEach((item) => {
        const accordionItem = document.createElement('div');
        const programsString = item.programs.flat().join(',');
        const audiencesString = item.audiences.flat().join(',');
        const categoryString = programsString + ',' + audiencesString;
        accordionItem.dataset.category = categoryString;
        accordionItem.classList.add(
          'accordion-item',
          'accordion-item-secondary'
        );
        accordionItem.innerHTML = `
            <button
							id="UPCOMING-${item.id}"
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-${item.id}"
              aria-expanded="false"
              aria-controls="accordion-${item.id}"
            >
              ${item.title}
              <span class="accordion-icon"></span>
            </button>
            <div
              id="accordion-${item.id}"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion-on-demand"
            >
              <div class="accordion-body">
                ${item.content}
              </div>
            </div>
          `;
        accordion.appendChild(accordionItem);
      });
      onDemandDiv.appendChild(accordion);
      document.getElementById('upcomingTrainings').appendChild(onDemandDiv);

      const allTrainingsDiv = document.createElement('div');
      allTrainingsDiv.classList.add('filter-trainings');
      allTrainingsDiv.innerHTML = `<h3 class="filter-trainings-heading">Trainings By Name</h3>`;
      Object.keys(sortedGroupByTitles).forEach((title) => {
        const item: any = sortedGroupByTitles[title];
        const id = title.replace(/\s/g, '').replace(/\W/g, '');
        const accordion = document.createElement('div');
        accordion.classList.add('accordion');
        const accordionItem = document.createElement('div');
        const programsString: string = item
          .map((item: any) => item.programs)
          .flat()
          .join(',');
        const audiencesString: string = item
          .map((item: any) => item.audiences)
          .flat()
          .join(',');
        const categoryString = programsString + ',' + audiencesString;
        accordionItem.dataset.category = categoryString;
        accordionItem.classList.add(
          'accordion-item',
          'accordion-item-secondary'
        );

        let itemHtml = `<button
							id="ALL-${id}"
              class="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordion-all-${id}"
              aria-expanded="false"
              aria-controls="accordion-${id}"
            >
              ${title}
              <span class="accordion-icon"></span>
            </button>
            <div
              id="accordion-all-${id}"
              class="accordion-collapse collapse"
              data-bs-parent="#accordion${title}"
            >
              <div class="accordion-body">`;

        let itemCount = 0;

        groupByTitles[title].forEach((item) => {
          if (itemCount > 0) {
            itemHtml += '<div class="accordion-divider"></div>';
          }
          itemHtml += item.content;
          itemCount++;
        });
        itemHtml += `</div>
            </div>`;
        accordionItem.innerHTML = itemHtml;
        accordion.appendChild(accordionItem);
        allTrainingsDiv.appendChild(accordion);
        document.getElementById('allTrainings').appendChild(allTrainingsDiv);
      });

      initAccordion();
    })
    .catch((error) => {
      console.error(error);
      // Handle the error here
    });
  const trainingsAnchor = document.querySelector('a[href="#trainings"]');
  const onDemandAnchors = document.querySelectorAll('a[href="#on-demand"]');
  const resetFilters = () => {
    (document.querySelector('.filter-by-reset') as HTMLElement).click();
  };

  const upcomingTrainingsTab: HTMLElement = document.querySelector(
    '#upcomingTrainings-tab'
  );
  trainingsAnchor?.addEventListener('click', (e) => {
    upcomingTrainingsTab?.click();
  });
  let onDemandAnchorClicked = false;
  onDemandAnchors.forEach((link) => {
    link.addEventListener('click', (e) => {
      upcomingTrainingsTab?.click();
      resetFilters();
      if (!onDemandAnchorClicked) {
        setTimeout(() => {
          onDemandAnchorClicked = true;
          (link as HTMLElement).click();
        }, 200);
      } else {
        onDemandAnchorClicked = false;
        return;
      }
    });
  });
}
