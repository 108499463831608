
/* Dropdown Menu TS */

// Function to close all menus - consider moving to a utility file if used by other components as well
export function closeAllMenus() {
  document.querySelectorAll('.menu-item, .mega-menu-item, .dropdown').forEach((item) => {
    item.classList.remove('menu-open', 'mega-menu-open', 'dropdown-open');
  });
  document.querySelectorAll('.menu-toggle-on, .mega-toggle-on, .dropdown-toggle-on').forEach((toggleItem) => {
    toggleItem.classList.remove('menu-toggle-on', 'mega-toggle-on', 'dropdown-toggle-on');
  });
  document.body.classList.remove('mega-menu-active');
  document.body.style.overflow = '';
}

function isClickOutside(element, event) {
  return !element.contains(event.target);
}

export function initDropdownMenu() {
  
  /* Simple Dropdown Menu */
  const menuToggles = document.querySelectorAll('.menu-toggle');
  if (menuToggles.length > 0) {
    menuToggles.forEach((toggle) => {
      toggle.addEventListener('click', (e) => {
        e.preventDefault();
        const parentDiv = toggle.closest('.menu-item');
        const isOpen = parentDiv.classList.contains('menu-open');

        closeAllMenus(); // Close all menus before toggling

        // Open the clicked menu if it was closed, close it otherwise
        if (!isOpen) {
          toggle.classList.add('menu-toggle-on');
          parentDiv.classList.add('menu-open');
        }
      });
    });
  }
  
  /* Dropdown Menu */
  const dropdownToggles = document.querySelectorAll('.dropdown-toggle');
  if (dropdownToggles.length > 0) {
    dropdownToggles.forEach((toggle) => {
      toggle.addEventListener('click', (e) => {
        e.preventDefault();
        const parentDiv = toggle.closest('.dropdown');
        const isOpen = parentDiv.classList.contains('dropdown-open');

        closeAllMenus(); // Close all menus before toggling

        // Open the clicked menu if it was closed, close it otherwise
        if (!isOpen) {
          toggle.classList.add('dropdown-toggle-on');
          parentDiv.classList.add('dropdown-open');
        }
      });
    });
  }

  /* Mega Menu */
  const megaMenuToggles = document.querySelectorAll('.mega-menu-toggle');
  if (megaMenuToggles.length > 0) {
    megaMenuToggles.forEach((toggle) => {
      toggle.addEventListener('click', (e) => {
        e.preventDefault();
        const parentDiv = toggle.closest('.mega-menu-item');
        const isOpen = parentDiv.classList.contains('mega-menu-open');

        // Check if we are on a mobile device by viewport width
        if (window.innerWidth < 992) {
          // For mobile devices, only toggle the current menu without closing others
          if (!isOpen) {
            toggle.classList.add('mega-toggle-on');
            parentDiv.classList.add('mega-menu-open');
            document.body.classList.add('mega-menu-active');
            document.body.style.overflow = 'hidden'; // Optionally manage body scroll here
          } else {
            // If the menu is open and is clicked, close it
            toggle.classList.remove('mega-toggle-on');
            parentDiv.classList.remove('mega-menu-open');
            // Check if there are no other open mega menus before removing these
            if (document.querySelectorAll('.mega-menu-open').length === 0) {
              document.body.classList.remove('mega-menu-active');
              document.body.style.overflow = ''; // Restore body scroll
            }
          }
        } else {
          // For non-mobile devices, keep the original behavior
          closeAllMenus(); // Close all menus before toggling

          if (!isOpen) {
            toggle.classList.add('mega-toggle-on');
            parentDiv.classList.add('mega-menu-open');
            document.body.classList.add('mega-menu-active');
            document.body.style.overflow = 'hidden';
          }
        }
      });
    });
  }

  /* ESC key functionality to close all menus */
  document.addEventListener('keydown', (e) => {
    if (e.key === 'Escape') {
      closeAllMenus();
    }
  });

  /* Click outside to close menus */
  document.addEventListener('click', (e) => {
    const menuItems = document.querySelectorAll('.menu-item, .mega-menu-item, .dropdown');
    let isOutside = true;

    menuItems.forEach((item) => {
      if (isClickOutside(item, e) === false) {
        isOutside = false;
      }
    });

    if (isOutside) {
      closeAllMenus();
    }
  });

  // Close dropdown menu when anchor link inside dropdown is clicked
  document.querySelectorAll('.dropdown-menu .menu-dropdown-link').forEach((link) => {
    link.addEventListener('click', () => {
      closeAllMenus();
    });
  });
  
  // The mobile menu and search form logic remains unchanged
  /* Mobile Menu */
  const menuToggleBtn = document.getElementById('mobile-menu-toggle');
  if (menuToggleBtn) {
    const menuMobileDiv = document.getElementById('main-nav');
    const mainBody = document.body;

    menuToggleBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const isOpen = mainBody.classList.contains('menu-open');

      if (!isOpen) {
        menuToggleBtn.classList.add('active');
        mainBody.classList.add('menu-open');
      } else {
        menuToggleBtn.classList.remove('active');
        mainBody.classList.remove('menu-open');
      }
    });
  }

  /* Mobile Search Form */
  const menuSearchBtn = document.getElementById('btn-search-toggle');
  if (menuSearchBtn) {
    const menuSearchDiv = document.getElementById('top-bar');
    const mainBody = document.body;

    menuSearchBtn.addEventListener('click', (e) => {
      e.preventDefault();
      const isOpen = mainBody.classList.contains('search-open');

      if (!isOpen) {
        menuSearchBtn.classList.add('active');
        menuSearchDiv.classList.add('active');
        mainBody.classList.add('search-open');
      } else {
        menuSearchBtn.classList.remove('active');
        menuSearchDiv.classList.remove('active');
        mainBody.classList.remove('search-open');
      }
    });
  }

  /* Mobile Menu Item ScrollTop */
  // Check if the viewport width is less than 768px and if .nav-link elements exist
  // if (window.innerWidth < 992 && document.querySelector('.nav-link')) {
  //   document.querySelectorAll('.nav-link').forEach(item => {
  //     item.addEventListener('click', function() {
  //       // Find the parent .nav-item of the clicked .nav-link
  //       const parentNavItem = this.closest('.nav-item');
        
  //       // Ensure there's a parent .nav-item found
  //       if (!parentNavItem) return;

  //       // Find the closest scrollable ancestor to the .nav-item, could be the main navigation container
  //       const scrollableNavContainer = parentNavItem.closest('.main-nav');
        
  //       if (!scrollableNavContainer) return; // Stop if no scrollable container found

  //       // Calculate the parent .nav-item's top position relative to the scrollable container
  //       const itemTopRelativeToContainer = parentNavItem.offsetTop - scrollableNavContainer.offsetTop + 32;
  //       //const itemTopRelativeToContainer = parentNavItem.offsetTop - scrollableNavContainer.offsetTop + 46;

  //       // Optionally, adjust the scroll to center the .nav-item or align it to the top as preferred
  //       const scrollPosition = itemTopRelativeToContainer; // Adjust this as needed

  //       // Scroll the container to the .nav-item position
  //       scrollableNavContainer.scrollTo({
  //           top: scrollPosition,
  //           behavior: 'smooth' // For smooth scrolling
  //       });
  //     });
  //   });
  // }

};