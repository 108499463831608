export function initToggleArticle() {
  // Check if there is at least one article and one button with the specified class on the page
  if(document.querySelector('.article') && document.querySelector('.btn-article-toggle')) {
    const toggleButtons = document.querySelectorAll('.article .btn-article-toggle');

    if(toggleButtons.length > 0) { // Check if the buttons actually exist
      toggleButtons.forEach(button => {
        button.addEventListener('click', function(e) {
          e.preventDefault();
          const article = this.closest('.article');
          const ellipsisSpan = article.querySelector('.ellipsis');
          const moreTextSpan = article.querySelector('.more-text');

          // Toggle the display based on the moreTextSpan's current state
          // If moreTextSpan is not visible, show it and hide the ellipsis
          if (moreTextSpan.style.display === 'none' || !moreTextSpan.style.display) {
            moreTextSpan.style.display = 'inline';
            ellipsisSpan.style.display = 'none';
            this.textContent = 'Show Less';
          } else { // If moreTextSpan is visible, hide it and show the ellipsis
            moreTextSpan.style.display = 'none';
            ellipsisSpan.style.display = 'inline';
            this.textContent = 'Show More';
          }
        });
      });
    }
  }
}